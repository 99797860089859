
import { Options, Vue } from 'vue-class-component';
import Portal from '@/views/Portal.vue';

@Options({
  components: {
    Portal
  },
})
export default class App extends Vue {}
