import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f8b5700"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "portal"
}
const _hoisted_2 = { class: "header-bar" }
const _hoisted_3 = { class: "name" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "control" }
const _hoisted_6 = { class: "option" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_chatbox = _resolveComponent("chatbox")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "box",
    style: _normalizeStyle($setup.boxStyle),
    "element-loading-background": "white"
  }, [
    ($setup.stage == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('message.cp')), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.hello')) + " " + _toDisplayString($setup.username), 1),
              _createVNode(_component_el_divider, {
                direction: "vertical",
                "content-position": "center"
              }),
              _createElementVNode("span", {
                class: "logout",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.logout && $setup.logout(...args)))
              }, _toDisplayString(_ctx.$t('message.logout')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_select, {
              modelValue: $setup.schema,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.schema) = $event)),
              placeholder: _ctx.$t('message.sel_schema')
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.schema,
                    label: item.title,
                    value: item.schema
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder"]),
            _createVNode(_component_el_button, {
              onClick: $setup.submit,
              style: {"margin-left":"6px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('message.confirm')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]))
      : _createCommentVNode("", true),
    ($setup.stage == 1)
      ? (_openBlock(), _createBlock(_component_chatbox, {
          key: 1,
          schema: $setup.schema,
          name: $setup.name,
          title: $setup.title,
          am: $setup.am,
          pm: $setup.pm
        }, null, 8, ["schema", "name", "title", "am", "pm"]))
      : _createCommentVNode("", true)
  ], 4)), [
    [_directive_loading, $setup.loading]
  ])
}