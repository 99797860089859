
import { defineComponent } from "vue-demi";
import { fetchInit, fetchMessage } from "@/api/chat";
import { openToast } from "toast-ts";
import { useI18n } from "vue-i18n";

export default defineComponent({
  data() {
    return {
      disabled: false,
      message: "",
      maxMsgId: 0,
      history: [] as any,
    };
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  props: {
    name: { type: String, default: "" },
    title: { type: String, default: "" },
    am: { type: String, default: "" },
    pm: { type: String, default: "" },
    error: { type: String, default: "" },
    schema: { type: String, default: "" }
  },
  async mounted() {
    let fail = false;
    let resp;
    try {
      resp = await fetchInit({ schema: this.schema });
      if (resp.code != 0) {
        fail = true;
      }
    } catch (error) {
      console.error(error);
      fail = true;
    }

    if (resp === undefined) {
      openToast(this.t('message.error'));
      return;
    }

    if (fail) {
      this.disabled = true;
      this.message = this.t('message.service_err');
      return;
    }

    let msgList = resp.data;

    msgList.forEach((msg) => {
      this.loadMsg(true, msg.content, msg.time);
    });
  },
  methods: {
    async loadMsg(bot: boolean, msg: string, time: number) {
      this.history.push({
        msg_id: this.maxMsgId++,
        bot: bot,
        content: msg,
        time: time,
      });
      await this.$nextTick();
      let historyContent = this.$refs.historyContent as any;
      let historyScrollbar = this.$refs.historyScrollbar as any;
      historyScrollbar.setScrollTop(historyContent.clientHeight);
    },
    zero(num: number) {
      return num < 10 ? "0" + num : num;
    },
    time(timestamp: number) {
      const date: Date = new Date(timestamp);
      let h: number = date.getHours();
      let m: number = date.getMinutes();
      return (
        this.zero(h) + ":" + this.zero(m) + " " + (h >= 12 ? this.pm : this.am)
      );
    },
    goback() {
      const parent = this.$parent as any
      parent.stage = 0
    },
    async send() {
      let msg: string = this.message;
      if (msg.length == 0) {
        return;
      }
      let time: number = new Date().getTime();
      this.message = "";
      this.loadMsg(false, msg, time);

      try {
        let resp = await fetchMessage({
          content: msg,
        });

        if (resp.code == 2) {
          msg = this.t('message.expire');
          time = new Date().getTime();
          this.loadMsg(true, msg, time);
        } else if (resp.code != 0) {
          msg = this.error;
          time = new Date().getTime();
          this.loadMsg(true, msg, time);
        } else {
          let data = resp.data;
          data.forEach((elem) => {
            this.loadMsg(true, elem.content, elem.time);
          });
        }
      } catch (error) {
        console.error(error);
        msg = this.error;
        time = new Date().getTime();
        this.loadMsg(true, msg, time);
      }
    },
  },
});
