
import Chatbox from "@/components/Chatbox.vue";
import { fetchOptions, fetchDetail } from "@/api/portal";
import { onBeforeMount, ref } from "vue";
import { openToast } from "toast-ts";
import { useI18n } from 'vue-i18n'
import { useStore } from '@/store'
import { setToken } from '@/utils/auth';

export default {
  name: "Portal",
  components: {
    Chatbox,
  },
  setup() {
    const stage = ref(0);
    const loading = ref(false);
    const schema = ref("");
    const boxStyle = ref({
    })
    const options: Array<{ schema: string; title: string }> = [];
    const am = ref("AM");
    const pm = ref("PM");
    const name = ref("bot1");
    const title = ref("service");
    const store = useStore()
    const username = store.$state.name
    

    const { t } = useI18n()

    onBeforeMount(async () => {
      loading.value = true;
      const options_ = await fetchOptions();
      options_.forEach((option) => {
        options.push({
          schema: option.schema,
          title: option.title,
        });
      });
      loading.value = false;
    });

    async function submit(): Promise<void> {
      if (schema.value === "") {
        openToast(t('message.sel_schema'));
        return;
      }
      loading.value = true;

      const detail = await fetchDetail({schema: schema.value})
      name.value = detail.name
      title.value = detail.title
      am.value = detail.am
      pm.value = detail.pm

      stage.value = 1
      loading.value = false
    }

    function logout() {
      setToken('')
      location.reload()
    }

    return {
      stage,
      loading,
      schema,
      options,
      boxStyle,
      name,
      title,
      am,
      pm,
      username,
      submit,
      logout
    };
  },
};
